import React from 'react'
import { Link } from 'react-router-dom';
import one from '../images/service1.webp';
import six from '../images/service2.webp';
import seven from '../images/service3.jpg';
import CardUI from './cardUI'
const ServicesComponent = () => {
  return (
    <>
    <h3 className='serviceheading'>Our Services</h3>
    <div className='underline mx-auto'></div>
    <div className='container d-flex justify-center'>
      <div className='row' >
        <div className='col-md-4'>
          <CardUI imgsrc={one} title="Hair Wig" description="We provide quality custom natural as well as synthetic hair wigs "  />
        </div>
        <div className='col-md-4'>
          <CardUI  imgsrc={six} title="Hair Patch"  description="We provide quality patches for men as well as women" />
        </div>
        <div className='col-md-4'>
          <CardUI imgsrc={seven} title="Hair Extension"  description="We provide hair extension for men as well as women" />
        </div>
        <div className='servicemore'> <Link to="/about"  className='btn btn-primary  shadow'>Check more services....</Link></div>
      </div>

    </div>
    </>
  );
}
export default ServicesComponent;

