import React from 'react'
import Footer from '../inc/footer'
import NavigationBar from '../inc/Navigationbar'
import ServicesComponent from './ServicesComponent'

const Services = () => {
  return (
    <>
    <NavigationBar/>
    <ServicesComponent/>
    <Footer/>
      
    </>
  )
}

export default Services
