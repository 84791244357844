
import img from '../images/about.webp';


  
  export const homeAboutUs = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Young Forever is leading Hair Studio in Delhi  ',
   
    description:'With over 10 years of experience in hair replacement, we have successfully treated a number of individuals who are now happy with the way they look. Our main aim is to satisfy our clients and bring a smile to their face. With lovely and gorgeous hair, you will regain your confidence and be able to face the world without any inhibitions in your mind. Getting hair replacement done from our clinic will be one of the best decisions you would have made so far.',

    buttonLabel: 'Call Now',
    
    imgStart: 'start',
    img: img,
    alt: 'Best Non surgical hair replacement'
  };
  
  