import React from 'react'
import Footer from '../inc/footer';
import NavigationBar from '../inc/Navigationbar';
import SuccessComponent from './SuccessComponent';

const SuccessSlider = () => {
  return (
    <>
     <NavigationBar/>
     <SuccessComponent/>
     <Footer/>
      
    </>
  );
}

export default SuccessSlider;
