import React from 'react';
import AboutComponent from './about/AboutComponent';
import ContactComponent from './contact/ContactComponent';
import Footer from './inc/footer';
import HomeSlider from './homeslider/HomeSlider';
import NavigationBar from './inc/Navigationbar';
import Services from './service/Services';
import SuccessSlider from './successpage/SuccessSlider';
import Whatsapp from './inc/whatsapp';
import Call from './inc/call';
import Wig from './service/wig';
import SuccessComponent from './successpage/SuccessComponent';
import ServicesComponent from './service/ServicesComponent';


const Home=()=> {
 return (
    <>
      <NavigationBar/>
      <HomeSlider/>
      <AboutComponent/>
       <Call/>
       <ServicesComponent/>
       <SuccessComponent/>
       <ContactComponent/>
       <Wig/>
      <Whatsapp/>
      <Footer/>
    </>
  );
}

export default Home;