import React from 'react'
import { Carousel } from 'react-bootstrap';
import one from '../images/home1.webp'
import two from '../images/home2.webp'
import three from '../images/home3.webp'
import "./HomeSlider.css";


const HomeSlider = () => {
  return (
  <>
   <Carousel>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="First slide"
        />
        <Carousel.Caption>
        <h3 className='homeheading'><strong>OFFER FOR NEW CUSTOMER </strong></h3>
          <p className='homeparagraph'><strong>30% on all services and free Membership.</strong></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}> 
        <img
          className="d-block w-100"
          src={two}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3 className='homeheading'><strong>OFFER FOR CHEMOTHERAPY PATIENT</strong></h3>
          <p className='homeparagraph'><strong>40% on all services and free Membership
          .</strong></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={three}
          alt="Third slide"
        />

        <Carousel.Caption>
        <h3 className='homeheading'><strong>CUSTOMIZED HAIR WIG </strong></h3>
          <p className='homeparagraph'>
         <strong>Starting from 4999/-.</strong>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
</>
   
  );
}

export default HomeSlider