import React from 'react'
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeroSection from '../HeroSection/HeroSection';
import { homeAboutUs } from '../HeroSection/HeroSectionData';

const about = () => {
  return (
    <>
    <h3 className='aboutheading'>About Young Forever</h3>
      <div className='underline mx-auto'></div>
     <div> <HeroSection {...homeAboutUs} /></div>
      <section className='section'>
        <div className="container">
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h3 className='main-heading'>Our Company</h3>
              <div className='underline mx-auto'></div>
              <p>
                With us get fuller and thicker looking hair instantly with Our  Solution, 
                the Best Hair Replacement Service in Delhi that specializes in providing 
                quality custom-made hair wigs for both men and women. we employ non-surgical
                and non-invasive hair replacement techniques. In comparison to hair implant
                and hair transplant surgical procedures which are painful,Our Integration 
                System is painless, gentle, and effective
              </p>
              <Link to="/about"  className='btn btn-warning shadow'>Read More</Link>
            </div>
          </div>
        </div>
    </section>
    {/*mission vision*/}
    <section className='section bg-c-light border-top'>
        <div className="container">
          <div className='row'>
            <div className='col-md-12 mb-5 text-center'>
              <h3 className='main-heading'>Our Mission,Vision and Values</h3>
              <div className='underline mx-auto'></div>
            </div>
            
            <div className='col-md-4 shadow'>
            <h6>Who We Are ?</h6>
              <p>
              Our clinic is a hair loss consultancy clinic that provides care and hair loss treatment 
              both men and women. The procedures followed by us are standard ones and are all certified. 
              You can come to us to get any of your hair related problem resolved. We will only be too
              happy to help you out!
                  
              </p>
            </div>
           
            <div className='col-md-4 shadow'>
            <h6 >What We Do?</h6>
              <p>
              At our clinic, we have a team that will offer you free consultations. You can speak to our experts 
          and get to know what kind of a hair replacement treatment is suitable for you. You can go ahead with 
          the treatment only after you are fully satisfied. You can rest be assured as everything will
          be taken care of by our team. We will be happy to brighten your life and add sparkle to it. 
             
              </p>
            </div>
            
            <div className='col-md-4 shadow'>
            <h6>Our Mission</h6>
              <p>
              The main aim of our clinic is to provide services and treatments to clients that are effective.
              Our team is always ready for challenges and strives hard for perfection. There is always zero compromise
              on quality. We want our clients to be happy and cheerful after getting treated from our place.
              We find joy in spreading smiles and changing lives.
             
              </p>
            </div>
          </div>
        </div>
    </section>
     
    </>
  );
}

export default about;
