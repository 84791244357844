import React from 'react'
import AboutComponent from './AboutComponent'
import Footer from '../inc/footer'
import NavigationBar from '../inc/Navigationbar'
import Whatsapp from '../inc/whatsapp'

const about = () => {
  return (
    <>
    <NavigationBar/>
    <AboutComponent/>
    <Whatsapp/>
    <Footer/>
    
      
    </>
  );
}

export default about;
