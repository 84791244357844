import React from 'react'
import ContactComponent from './ContactComponent';
import Footer from '../inc/footer';
import NavigationBar from '../inc/Navigationbar';
import Whatsapp from '../inc/whatsapp';

const contact = () => {
  return (
    <>
    <NavigationBar/>
    <ContactComponent/>
    <Whatsapp/>
    <Footer/>
      
    </>
  );
}

export default contact;
