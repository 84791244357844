/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import Whatsapp from '../inc/whatsapp';
import './contact.css';

const ContactComponent = () => {
  
  return (
    <>
     <h3 className='contactheading'>Get in Touch</h3>
      <div className='underline mx-auto'></div>
      <div className='col-md-12'>
      <Form style={{maxWidth:"90%" ,float:'inline-start'}} className='col-md-12'>
      <Form.Group className="mb-3 " controlId="formBasicName">
       <Form.Label>Your Full Name</Form.Label>
        <Form.Control type="name" placeholder="Enter Full Name" />
      </Form.Group>
      <Form.Group className="mb-3 " controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        <Form.Select aria-label="Default select example"  className="mb-3 ">
        
          <option>Select Service</option>
          <option value="1">Hair Wig for Men</option>
          <option value="2">Hair Wig for Women</option>
          <option value="3">Hair Fixing service</option>
          <option value="4">Hair Wefting service</option>
          <option value="5">Hair Weaving service</option>
          <option value="6">Hair Patch service</option>
          <option value="7">Hair Extension service</option>
         
    </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Write Your Query</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Send
      </Button>
    </Form>
    </div>
    <div className='col-md-12'>
    <iframe   className='col-md-12'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.120802135419!2d77.24433071455803!3d28.56613499383497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3d6d7134e97%3A0xf87a31db29406b0d!2sYoung%20Forever%20Hair%20Replacement%20Studio!5e0!3m2!1sen!2sin!4v1665315856958!5m2!1sen!2sin"  
          width="100%" height="500" 
          style={{border:0}}
          allowFullScreen
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade" >
        </iframe>
        </div>
        <Whatsapp/>
        
    
    </>
  )
}

export default ContactComponent;
