import React from 'react'
import { Carousel } from 'react-bootstrap';
import one from '../images/wig1.jpg';
import r1 from '../images/review1.webp';
import r2 from '../images/review2.webp';
import r3 from '../images/review3.webp';
import r4 from '../images/review4.webp';
import r5 from '../images/review5.webp';
import r6 from '../images/review6.webp';
import "./SuccessStyle.css";



const SuccessComponent = () => {
  return (
  <>
  <h2 className='successheading'> See what Our client say !!!!!</h2>
  <div className='underline mx-auto'></div>
  <Carousel>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="First slide"
        />
        <Carousel.Caption>
        <img
          className="d-block w-90"
          src={r1}
          
        />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}> 
        <img
          className="d-block w-100"
          src={one}
          alt="Second slide"
        />
        <Carousel.Caption>
        <img
          className="d-block w-90"
          src={r2}
          
        />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="Third slide"
        />
        <Carousel.Caption>
        <img
          className="d-block w-90"
          src={r3}
          
        />
        </Carousel.Caption>
     </Carousel.Item>
     <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="Third slide"
        />
        <Carousel.Caption>
        <img
          className="d-block w-90"
          src={r5}
          
        />
        </Carousel.Caption>
     </Carousel.Item>
     <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="Third slide"
        />
        <Carousel.Caption>
        <img
          className="d-block w-90"
          src={r4}
          
        />
        </Carousel.Caption>
     </Carousel.Item>
    </Carousel>
  </>
  );
}
export default SuccessComponent;