import React from 'react'
import styled from 'styled-components';



const Call = () => {
    const Section=styled.section`
    background-color: #1c2237;
    padding: 2rem 4rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #f28a28;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    
    max-width:100%;
    
    `;
  
  return (
    <>
    <div className='col-md-12'>
        <Section><h2>CALL NOW FOR FREE CONSULTATION  <a href="tel:8882322766">+91-8882322766</a></h2></Section>
        
    </div>
    </>
   );
}
export default Call;