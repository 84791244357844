import React from 'react';
import './HeroSection.css';



function HeroSection({ lightBg, topLine, lightText, lightTextDesc, headline, description, img, alt,buttonLabel, imgStart }: {
    lightBg: any;
    topLine: any;
    lightText: any;
    lightTextDesc: any;
    headline: any;
    description: any;
    img: any;
    alt: any;
    imgStart: any;
    buttonLabel:any;
   
     }): JSX.Element{

  return (
    <>
      < div className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'} >
        <div className='container'>
          <div  className='row home__heroj-row' style={{display: 'flex',flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
            <div className='col-md-6'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p className={lightTextDesc? 'home__hero-subtitle' : 'home__hero-subtitle dark' }>
                  {description}
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='home__hero-img-wrapper'>
                <img src={img} alt={alt} className='home__hero-img' />
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
    </>

  );
}

export default HeroSection;