import React from 'react'
import styled from 'styled-components';

const Footer = () =>{
 
  return(
    <>
    <footer className="page-footer font-small blue pt-4" style={ {backgroundColor: '#1c2237'} }>
      <div className="container-fluid text-center text-md-left" style={{color:'white'}} >
        <div className="row">
            <div className="col-md-4 mt-md-0 mt-3">
                <h5 className="text-uppercase"><strong>Young Forever</strong></h5>
                <p>At young forever </p>
                <p>you can get quality Hair service at premium price,</p>
                <p>Please give us a chance to help you out!!!!</p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0"/>

            <div className="col-md-4 mb-md-0 mb-3">
                <h5 className="text-uppercase"> <strong>Quick Links</strong></h5>
                <ul className="list-unstyled">
                    <li><a href="/about" style={{color:'white'}}>About Us</a></li>
                    <li><a href="/services"style={{color:'white'}}>Services</a></li>
                    <li><a href="review"style={{color:'white'}}>Success Story</a></li>
                    <li><a href="/contact"style={{color:'white'}}>Contact Us</a></li>
                </ul>
            </div>

            <div className="col-md-4 mb-md-0 mb-3">
                <h5 className="text-uppercase"><strong>Address</strong></h5>
                <ul className="list-unstyled">
                    <li><strong>Lal Sai Market</strong></li>
                    <li><strong>O-3, FIRST FLOOR, near TIWARI SWEET, Lajpat Nagar II</strong></li>
                    <li><strong>Delhi 110024</strong></li>
                    <li><a href="tel:8882322766" style={{color:'white'}}>+91-8882322766</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-3">
       
        <a href="/" style={{color:'white'}}>  YF:© 2020 Copyright</a>
        
    </div>
   </footer>
   </>

  );

} 
   

export default Footer;
