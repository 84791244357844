import React from 'react'
import Footer from '../inc/footer';
import NavigationBar from '../inc/Navigationbar';
import CardUI from './cardUI';
import w1 from '../images/wig3.jpg';

const wig = () => {
  return (
        <>
        
         <div className='col-md-12'>
         <div className='col-md-4'>
         </div>

         </div>
         
        </>
      );
}

export default wig;
