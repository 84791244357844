import React from 'react'
import {BrowserRouter as Router,Route, Switch } from 'react-router-dom'
import './App.css';
import Home from './Components/home';
import About from './Components/about/about';
import Contact from './Components/contact/contact';
import Services from './Components/service/Services';
import SuccessSlider from './Components/successpage/SuccessSlider';

const App=() =>{
 return (
  <>
  <Router> 
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/services" component={Services} />
      <Route path="/successstory" component={SuccessSlider}/>
      <Route path="/contact" component={Contact}/>

    </Switch>
  </Router>
  </>
  );
}

export default App;
