import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React from 'react';


const NavigationBar=() =>{
 
  return (
    <Navbar  expand="lg"  style={{ minHeight:'5rem' , backgroundColor: '#1c2237' } }>
      <Container className='navbar-container' style={{color:'white'}} >
        <Navbar.Brand href="/" style={{color:'white'}}>Young Forever</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:'white'}} />
        <Navbar.Collapse id="basic-navbar-nav" style={{color:'white'}}>
          <Nav className="ms-auto" style={{color:'white'}}>
            <Nav.Link href="/" style={{color:'white'}} >Home</Nav.Link>
            <Nav.Link href="/services" style={{color:'white'}}>Services</Nav.Link>
            <Nav.Link href="/about" style={{color:'white'}} >About</Nav.Link>
            <Nav.Link href="/gallery" style={{color:'white'}}>Gallery</Nav.Link>
            <Nav.Link href="/successstory" style={{color:'white'}}>Success Story</Nav.Link>
            <Nav.Link href="/products" style={{color:'white'}}>Products</Nav.Link>
            <Nav.Link href="/contact" style={{color:'white'}}>Contact Us</Nav.Link>
             
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;